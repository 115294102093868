import '../styles/accordion.scss';

import React, { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';

function FaqSection( { faqs = [{ question: '', answer: '' }] } ) {
  const [showArrow, setArrow] = useState( -1 );

  function clicked( e, i ){
    if( showArrow === i ){
      setArrow( -1 );
    } else {
      setArrow( i );
    }
  }

  return(
    <>
      <div className="faq-box">
        <div className="container common-container-space">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="faq-box-head">
                Frequently Asked Questions
              </h2>
            </div>
          </div>

          <Accordion>
            <div className="row flexRow">
              {
                faqs.map( ( { question, answer }, i ) => {
                  return(
                    <div key={i} className="col-lg-6 col-12">
                      <Card >
                        <Accordion.Toggle onClick={( e ) => clicked( e, i )} as={Card.Header} eventKey={i + 1}>
                          <div className="flexRow align-items-center justify-content-between">

                            <button className="btn btn-link">
                              { question }
                            </button>

                            <img className={showArrow !== i ? 'select-box-icon' : 'select-box-icon rotate'} src= 'https://cdn.onlinewebfonts.com/svg/img_295694.svg'
                              alt="Arrow Icon" aria-hidden="true" />

                          </div>
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey={i + 1}>
                          <Card.Body>
                            { answer }
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                  );
                } )
              }
            </div>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default FaqSection;
